@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scroll Bar CSS */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
body {
  font-family: "Nunito Sans", sans-serif !important;
}
h3{
  font-weight: 700;
}
